import React, {Component} from 'react';
import { Menu, Responsive, Dropdown } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom'
import './Nav.css';

const DesktopNavItem = ({name, link, exact}) => (
    <Menu.Item as={NavLink} exact={exact} to={link} name={name}></Menu.Item>
);

class Nav extends Component {
    render() {
        return (
            <div className="Nav">
                <Menu pointing secondary size="huge">
                    <Responsive as={DesktopNavItem} link="/" exact={true}
                        name="Home" minWidth={790}
                    />
                    <Responsive as={DesktopNavItem} link="/about"
                        name="About" minWidth={790}
                    />
                    <Responsive as={DesktopNavItem} link="/projects"
                        name="Projects" minWidth={790}
                    />
                    <Responsive as={DesktopNavItem} link="/photos"
                        name="Photos" minWidth={790}
                    />
                    <Responsive as={Menu.Menu} maxWidth={789}>
                        <Dropdown
                            item
                            icon='bars'>
                                <Dropdown.Menu>
                                    <Dropdown.Item  as={NavLink} exact to="/" text="Home"></Dropdown.Item>
                                    <Dropdown.Item  as={NavLink} to="/about" text="About"></Dropdown.Item>
                                    <Dropdown.Item  as={NavLink} to="/projects" text="Projects"></Dropdown.Item>
                                    <Dropdown.Item  as={NavLink} to="/photos" text="Photos"></Dropdown.Item>
                                </Dropdown.Menu>
                        </Dropdown>
                    </Responsive>
                </Menu>
            </div>
        );
    }

}

export default Nav;