import React, { Component } from 'react';
import './Projects.css';
import { Container, Grid, Icon } from 'semantic-ui-react';
import project_data from '../../data/projects.json';

class Projects extends Component {

  createProjects = () => {
    const projectMap = project_data["projects"].map((project, i) => {
      return (
        <Grid.Row key={i}>
          <Grid.Column width={3} verticalAlign="middle">
            <Icon name={project["icon"]} size="massive" color={project["color"]}></Icon>
          </Grid.Column>
          <Grid.Column width={13}>
            <h4 className="text-left">{project["title"]}</h4>
            <p className="text-left pb-2"><strong>Skills: </strong>{project["skills"]}</p>
            <p className="text-left">{project["blurb"]}</p>
          </Grid.Column>
        </Grid.Row>
      )
    })
    return projectMap;
  }

  render() {
    return (
      <Container className="Projects row px-0 pt-3">
        <h1 className="text-center p-3">Projects</h1>
      <Grid celled className="mx-auto px-0">
        {this.createProjects()}
      </Grid>
    </Container>
    )
  }
}

export default Projects;
