import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Nav from './Components/Nav/Nav'
import Footer from './Components/Footer/Footer'
import Home from './Components/Home/Home'
import About from './Components/About/About'
import Photos from './Components/Photos/Photos'
import Projects from './Components/Projects/Projects'
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App-container">
          <Router>
          <Nav></Nav>
            <Switch>
              <Route path="/about">
                <About></About>
              </Route>
              <Route path="/projects">
                <Projects></Projects>
              </Route>
              <Route path="/photos">
                <Photos></Photos>
              </Route>
              <Route path="/">
                <Home></Home>
              </Route>
            </Switch>
          </Router>
          <Footer></Footer>
      </div>
    );
  }
}

export default App;
