import React, { Component } from 'react';
import { Container } from 'semantic-ui-react';
import { Carousel } from 'react-bootstrap';
import './Home.css';
import photos_data from '../../data/photos.json';

class Home extends Component {

  carouselView = () => {
    const homePhotos = photos_data["home"]
    const homeCarousel = homePhotos.map(photo => {
      return (
        <Carousel.Item>
          <img
            className="carousel-img"
            src={"assets/images/"+photo["file"]}
            alt={photo["name"]}
          />
          <Carousel.Caption>
            <p>{photo["caption"]}</p>
          </Carousel.Caption>
        </Carousel.Item>
      )
    })
    return (<Carousel className="mh-100">
      {homeCarousel}
    </Carousel>)
  }

  render() {
    return (
      <Container className="Home row px-0 pt-3">
        <h1 className="text-center p-2">Kristen Koyanagi</h1>
        {this.carouselView()}
      </Container>
    );
  }
}

export default Home;
