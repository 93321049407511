import React, { Component } from 'react';
import './About.css';
import { Image, Grid, Menu, Segment, Container, List } from 'semantic-ui-react';
import about_data from '../../data/about.json';

const ImageRef = (props) => (
  <Image src={"assets/images/about/"+props.name+".png"} size='medium' circular />
)

class About extends Component {
  state = { activeItem: 'bio' }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  bioBlurb = () => {
    return about_data["bio"]
  }

  certBlurb = () => {
    const certList = about_data["certifications"]
    const listItems = certList.map(cert =>{
      return <List.Item key={cert["name"]}>
        <List.Icon name={cert["icon"]} size="large" verticalAlign="middle"></List.Icon>
        <List.Content>
          <List.Header as='a' href={cert["link"]} target="_blank">{cert["name"]}</List.Header>
          <List.Description>{cert["date"]}</List.Description>
        </List.Content>
      </List.Item>
    })
    return (
      <List divided relaxed>{listItems}</List>
    )
  }

  experiencesBlurb = () => {
    const experienceMap = about_data["experiences"].map((experience, i) => {
        const positions = experience.position.map((position, i) => {
            return  (
            <List.Description className="pb-2" key={i}>
              <span className="text-dark">{position["title"]}</span><br></br>
              <i>{position["years"]}</i>
            </List.Description>)
        })
        return (<List.Item key={i}>
          <List.Header className="pb-1">{experience["company"]}</List.Header>
          {positions}
        </List.Item>)
    })
    return (<List>{experienceMap}</List>)
  }

  iconBlurb = (section) => {
    const iconMap = about_data[section].map((interest, i) => {
      return (<List.Item key={i}>
                <List.Icon name={interest["icon"]}  size="large"/>
                <List.Content>{interest["name"]}</List.Content>
              </List.Item>)
    })
    return <List>{iconMap}</List>
  }

  segmentSelection = () => {
    const segmentBlurbs = {
      'bio': this.bioBlurb(),
      'skills': this.iconBlurb("skills"),
      'certifications': this.certBlurb(),
      'experiences': this.experiencesBlurb(),
      'interests': this.iconBlurb("interests")
    }
    return (
    <Segment attached='bottom' className="text-left">
      {segmentBlurbs[this.state.activeItem]}
    </Segment>)
  }

  render() {
    const { activeItem } = this.state
    return (
      <Container className="About row">
        <Grid>
          <Grid.Row className="p-5">
            <Grid.Column>
              <h1>About</h1>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={6}>
              <ImageRef name="CherryBlossom"></ImageRef>
            </Grid.Column>
            <Grid.Column width={10}>
              <Menu attached="top" tabular size="large" widths={5}>
                <Menu.Item
                  name='bio'
                  active={activeItem === 'bio'}
                  onClick={this.handleItemClick}
                />
                <Menu.Item
                  name='skills'
                  active={activeItem === 'skills'}
                  onClick={this.handleItemClick}
                />
                <Menu.Item
                  name='certifications'
                  active={activeItem === 'certifications'}
                  onClick={this.handleItemClick}
                />
                <Menu.Item
                  name='experiences'
                  active={activeItem === 'experiences'}
                  onClick={this.handleItemClick}
                />
              <Menu.Item
                  name='interests'
                  active={activeItem === 'interests'}
                  onClick={this.handleItemClick}
                />
              </Menu>
              {this.segmentSelection()}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

export default About;
