import React, { Component } from 'react';
import './Footer.css';
import { Navbar, Container, Row, Col } from 'react-bootstrap';
import { Icon } from 'semantic-ui-react';
import footer_data from '../../data/footer.json';

class Footer extends Component {

  createFooter = () => {
    const footerMap = footer_data["social_info"].map((social, i) => {
      return (
        <a href={social["link"]} target="_blank" rel="noopener noreferrer" key={i}>
          <Icon name={social["icon"]} size="large" color={social["color"]}></Icon>
        </a>
      )
    })
    return (<div>{footerMap}</div>)
  }

  render () {
      return (
      <div className="Footer">
          <Navbar className="justify-content-center footer-nav">
              <Container className="justify-content-center">
                <Row>
                  <Col>
                    <p>	&copy; Kristen Koyanagi 2020</p>
                    {this.createFooter()}
                  </Col>
                </Row>
              </Container>
          </Navbar>
      </div>);
  }
}

export default Footer;
