import React, { Component } from 'react';
import './Photos.css';
import { Container, Grid, Image } from 'semantic-ui-react';
import photos_data from '../../data/photos.json';

class Photos extends Component {

  createPhotos = () => {
    const photosMap = photos_data["photos"].map((photo, i) => {
      return (
        <Grid.Column width={4} key={i}>
          <Image src={"assets/images/"+photo["file"]}/>
        </Grid.Column>
      )
    })
    return (
      <Grid.Row>{photosMap}</Grid.Row>
    )

  }

  render() {
    return (
      <Container className="Photos row px-0 pt-3">
        <h1 className="text-center p-3">Photos</h1>
        <Grid className="mx-auto px-0">
          {this.createPhotos()}
        </Grid>
      </Container>
    );
  }
}

export default Photos;
